import React, {useState, useEffect, useReducer} from "react";
import openSocket from "../../services/socket-io";

import {
    Button,
    makeStyles,
    Paper,

    InputAdornment,
    TextField,
    Tabs,
    Tab,
    Badge,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import ScheduleModal from "../../components/ScheduleModal";
import TabPanel from "../../components/TabPanel";
import {ScheduleTabs} from "../../components/ScheduleTabs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import brLocale from 'date-fns/locale/pt-BR';
import {Box} from "@mui/system";
import {Stack} from "@mui/material";


const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    ticketsWrapper: {
        position: "relative",
        top: 0,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    badge: {
        right: "-10px",
    },

}));

const Schedules = () => {
    const classes = useStyles();

    const [createScheduleModal, setCreateScheduleModal] = useState(false);
    const [searchParam, setSearchParam] = useState("");

    /********************************/
    const [tab, setTab] = useState('Pendente');
    const [tabOpen, setTabOpen] = useState('Pendente');
    const [pendingCount, setPendingCount] = useState(0);
    const [sentCount, setSentCount] = useState(0);

    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);


    const handleOpenScheduleModal = () => {
        setCreateScheduleModal(true);
    }

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    /***************************************/
    const handleChangeTabOpen = (e, newValue) => {
        setTabOpen(newValue);
    };

    const handleUploadCSV = () => {


    }


    return (
        <MainContainer>

            <ScheduleModal
                open={createScheduleModal}
                onClose={() => setCreateScheduleModal(false)}
            />


            <MainHeader>
                <Title>Agendamentos</Title>
                <MainHeaderButtonsWrapper>

                    {/* <Button
            variant="contained"
            color="primary"
            onClick={handleOpenScheduleModal}
          >
            Importar CSV
          </Button> */}

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenScheduleModal}
                    >
                        Agendar nova mensagem
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Stack direction={'row'} sx={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-start',}}>
                <Box style={{padding: 10, minWidth: '35ch'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                        <DatePicker
                            value={initialDate}
                            onChange={(newValue) => {
                                setInitialDate(newValue)
                            }}
                            label="Data inicial"
                            renderInput={(params) => <TextField variant="outlined" size="small" fullWidth {...params}
                                                                sx={{width: '20ch'}}/>}

                        />
                    </LocalizationProvider>
                </Box>

                <Box style={{padding: 10, minWidth: '35ch'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                        <DatePicker
                            value={finalDate}
                            onChange={(newValue) => {
                                setFinalDate(newValue)
                            }}
                            label="Data final"
                            renderInput={(params) => <TextField
                                variant="outlined"
                                size="small"
                                fullWidth {...params} sx={{width: '20ch',}}/>}
                        />
                    </LocalizationProvider>
                </Box>
            </Stack>

            <TabPanel value={tab} name="Pendente" className={classes.ticketsWrapper}>

                <Tabs
                    value={tabOpen}
                    onChange={handleChangeTabOpen}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth">

                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={pendingCount}
                                color="primary"
                            >
                                Mensagens agendadas
                            </Badge>
                        }
                        value={"Pendente"}
                    />

                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                // badgeContent={sentCount}
                                color="secondary"
                            >
                                Mensagens enviadas
                            </Badge>
                        }
                        value={"Enviado"}
                    />

                </Tabs>

                <Paper className={classes.ticketsWrapper}>

                    <ScheduleTabs
                        initialDate={initialDate}
                        finalDate={finalDate}
                        status={'Pendente'}
                        tabOpen={tabOpen}
                        updateCount={setPendingCount}
                    />

                    <ScheduleTabs
                        initialDate={initialDate}
                        finalDate={finalDate}
                        status={'Enviado'}
                        tabOpen={tabOpen}
                        updateCount={setSentCount}
                    />


                </Paper>

            </TabPanel>

        </MainContainer>
    );
};

export default Schedules;




