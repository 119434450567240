import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Chip
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Autocomplete, { createFilterOptions, } from "@material-ui/lab/Autocomplete";
import { Stack } from "@mui/material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DateTimePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Too Short!")
    .max(15, "Too Long!")
    .required("Required"),
  message: Yup.string()
    .min(8, "Too Short!")
    .max(30000, "Too Long!")
    .required("Required"),
});



const filter = createFilterOptions({
  trim: true,
});

const ScheduleModalEdit = ({ open, onClose, shceduleId }) => {

  const classes = useStyles();
  const isMounted = useRef(true);

  const [contacts, setContacts] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [loading, setLoading] = useState(false);
  let fetchTimeout;

  useEffect(() => {

    if (!open) return;

    fetchContacts();
    handleGetSchedule();


  }, [open]);


  const handleGetSchedule = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/schedule/${shceduleId}`);
      console.log(data);
      setScheduleData(data);

    } catch (error) {

      toastError(error);

    } finally {
      setLoading(false);
    }

  }

  const fetchContacts = async () => {
    setLoading(true);

    clearTimeout(fetchTimeout);

    try {
      const { data } = await api.get("contacts");
      setContacts(data.contacts);
    } catch (err) {
      toastError(err);
    } finally {

      fetchTimeout = setTimeout(() => {
        setLoading(false);
      }, 100);

    }
  };

  const renderOptionLabel = option => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const handleSelectOption = (e, newValue) => {

    setScheduleData({ ...scheduleData, contact: newValue });
  };

  const handleClose = () => {
    onClose();
  };

  const handleUpdateSchedule = async () => {
    setLoading(true);

    try {
      const { data } = await api.put(`/schedule/${shceduleId}`, scheduleData);
      toast.success('Alterado com sucesso');
      onClose();
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <CircularProgress />

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">

          Editar agendamento
        </DialogTitle>

        <DialogContent dividers>

          <Stack direction={'column'} spacing={2}>

            <Autocomplete
              sx={{ maxWidth: '100px' }}
              options={contacts}
              value={scheduleData?.contact}
              id="multiple-limit-tags"
              getOptionLabel={renderOptionLabel}
              onChange={(e, newValue) => handleSelectOption(e, newValue)}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}

                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params}
                  label={i18n.t("newTicketModal.fieldLabel")}
                  variant="outlined"
                  autowidth
                  placeholder="Contatos"

                />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
              <DateTimePicker
                value={scheduleData?.scheduleAt}
                onChange={(newValue) => { setScheduleData({ ...scheduleData, scheduleAt: newValue }) }}
                label="Data e hora do agendamento"
                renderInput={(params) => <TextField variant="outlined" {...params} />}
              />
            </LocalizationProvider>




          </Stack>

          <div className={classes.textQuickAnswerContainer}>

            <TextField
              value={scheduleData?.message}
              onChange={(e) => { setScheduleData({ ...scheduleData, message: e.target.value }) }}
              label={'Mensagem a ser enviada'}
              variant="outlined"
              margin="dense"
              className={classes.textField}
              multiline
              rows={8}
              fullWidth
            />

          </div>

          {/* <div className={classes.textQuickAnswerContainer}>
            <TextField
              disabled={selectedContact.length > 1 ? true : false}
              // disabled={selectedContact.length > 1}
              variant="outlined"
              fullWidth
              type={'file'}
            />
          </div> */}

        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            // disabled={isSubmitting}
            variant="outlined"
          >
            {i18n.t("quickAnswersModal.buttons.cancel")}
          </Button>
          <Button
            onClick={handleUpdateSchedule}
            color="primary"
            // disabled={isSubmitting}
            variant="contained"
            className={classes.btnWrapper}
          >

            {i18n.t("SALVAR")}

          </Button>
        </DialogActions>



      </Dialog>
    </div>
  );
};

export default ScheduleModalEdit;
