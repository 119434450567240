import React, {useState, useEffect, useReducer} from "react";

import {
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {Edit, DeleteOutline} from "@material-ui/icons";
import openSocket from "../../services/socket-io";

import api from "../../services/api";
import {i18n} from "../../translate/i18n";
import ConfirmationModal from "../../components/ConfirmationModal";
import {toast} from "react-toastify";
import toastError from "../../errors/toastError";
import {format} from "date-fns";
import ScheduleModalEdit from "../../components/ScheduleModalEdit";
import {CircularProgress} from "@mui/material";


const reducer = (state, action) => {

    if (action.type === "LOAD_SCHEDULES") {
        const schedules = action.payload;
        const newSchedules = [];

        schedules.forEach((schedule) => {

            const scheduleIndex = state.findIndex((q) => q.id === schedule.id);

            if (scheduleIndex !== -1) {
                state[scheduleIndex] = schedule;
            } else {
                newSchedules.push(schedule);
            }
        });

        return [...state, ...newSchedules];
    }

    if (action.type === "UPDATE_SCHEDULES") {

        const schedules = action.payload;

        const scheduleIndex = state.findIndex((q) => q.id === schedules.id);

        if (scheduleIndex !== -1) {
            state[scheduleIndex] = schedules;
            return [...state];
        }

        return [...state];
    }

    if (action.type === 'CREATE_SCHEDULE') {
        const schedule = action.payload;
        return [...state, ...schedule];
    }

    if (action.type === "DELETE_SCHEDULE") {
        const scheduleId = action.payload;

        const scheduleIndex = state.findIndex((s) => s.id === scheduleId);
        if (scheduleIndex !== -1) {
            state.splice(scheduleIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    ticketsWrapper: {
        position: "relative",
        top: 0,
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    badge: {
        right: "-10px",
    },

}));


export const ScheduleTabs = (props) => {

    const classes = useStyles();

    const {status, tabOpen, updateCount, initialDate, finalDate} = props;

    const [scheduleList, dispatch] = useReducer(reducer, []);

    const [editingShceduleModal, setEditingSheduleModal] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deletingSchedule, setDeletingSchedule] = useState(null);
    const [editingShedule, setEditingShedule] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listContacts, setListContacts] = useState([]);


    useEffect(() => {

        dispatch({type: "RESET"});

        const delayDebounceFn = setTimeout(() => {
            fetchSchedules();

        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [initialDate, finalDate]);

    useEffect(() => {

        fetchContacts();
        const socket = openSocket();

        socket.on("schedule", (data) => {

            setLoading(true);

            if (data.action === "delete") {
                dispatch({type: "DELETE_SCHEDULE", payload: +data.scheduleId,});
                updateCount(prevState => prevState - 1);
            }

            if (data.action === "create") {
                dispatch({type: "CREATE_SCHEDULE", payload: data.schedules});
                updateCount(prevState => prevState + 1);
            }

            if (data.action === "update") {
                dispatch({type: "UPDATE_SCHEDULES", payload: data.schedules});
                updateCount(prevState => prevState);
            }

            setLoading(false);

        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const fetchSchedules = async () => {
        try {
            setLoading(true);

            const {data} = await api.get(`/schedules?status=${status}`, {
                params: {initialDate, finalDate},
            });

            dispatch({type: "LOAD_SCHEDULES", payload: data});

            if (typeof updateCount === "function") {
                updateCount(data.length);
            }

        } catch (error) {
            toastError(error);
        } finally {
            setLoading(false);
        }
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return {width: 0, height: 0, display: 'none'};
        }
    };

    const limitText = (text, length) => {

        return text.length > length ? text.substr(0, length) + ' ...' : text;

    }

    const handleEditSchedule = (schedule) => {
        setEditingShedule(schedule);
        setEditingSheduleModal(true);
    };

    const handleDeleteSchedule = async (scheduleId) => {
        try {
            await api.delete(`/schedule/${scheduleId}`);
            toast.success('Agendamento excluído com sucesso!');
        } catch (error) {
            toastError(error);
        }

        setDeletingSchedule(null);


    };

    const fetchContacts = async () => {
        setLoading(true);

        try {
            const {data} = await api.get("contacts");
            setListContacts(data.contacts);
        } catch (err) {
            toastError(err);
        } finally {

            setLoading(false);

        }
    };

    const searchContact = (id) => {
        const contact = listContacts.find((contact) => contact.id === id);
        return contact?.name;
    }


    return (

        <>

            <ConfirmationModal
                title={deletingSchedule && `Excluir agendamento de ${deletingSchedule?.contact ? deletingSchedule?.contact?.name : searchContact(deletingSchedule?.contactId)}`}
                // title={deletingSchedule && `Excluir agendamento de ${deletingSchedule?.contact?.name}`}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
            >
                {i18n.t("quickAnswers.confirmationModal.deleteMessage")}
            </ConfirmationModal>


            <ScheduleModalEdit
                open={editingShceduleModal}
                onClose={() => setEditingSheduleModal(false)}
                shceduleId={editingShedule?.id}
            />

            <Paper
                className={classes.mainPaper}
                variant="outlined"
                style={applyPanelStyle(status)}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Contato</TableCell>
                            <TableCell align="center">Mensagem</TableCell>
                            <TableCell align="center">Data de envio</TableCell>
                            <TableCell align="center">Conexão</TableCell>
                            <TableCell align="center">Status</TableCell>
                            {status === 'Pendente' && (
                                <TableCell align="center">{i18n.t("quickAnswers.table.actions")}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>

                            {loading ?
                                <CircularProgress/>
                                :
                                scheduleList.map((schedule) => (
                                    <TableRow key={schedule.id + new Date(schedule.scheduleAt).getTime()}>
                                        <TableCell align="center">{schedule?.id}</TableCell>
                                        <TableCell
                                            align="center">{schedule?.contact ? schedule?.contact?.name : searchContact(schedule.contactId)}</TableCell>
                                        <TableCell align="center">{limitText(schedule?.message, 30)}</TableCell>
                                        <TableCell
                                            align="center">{format(new Date(schedule?.scheduleAt), "dd/MM/yyyy HH:mm")}</TableCell>
                                        <TableCell align="center">{schedule?.whatsappName}</TableCell>
                                        <TableCell align="center">{schedule?.status}</TableCell>
                                        <TableCell align="center">
                                            {schedule?.status === 'Pendente' && (
                                                <>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleEditSchedule(schedule)}
                                                    >
                                                        <Edit/>
                                                    </IconButton>

                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            setConfirmModalOpen(true);
                                                            setDeletingSchedule(schedule);
                                                        }}
                                                    >
                                                        <DeleteOutline/>
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </>
    )
}