// Objetivo: Exibir um modal para adicionar ou editar uma fila de atendimento
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
}));

const QueueSelectOne = ({ selectedFatherQueueId, onChange, fatherqueue }) => {
    const classes = useStyles();
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/queue0");
                setQueues(data);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    const queuesWithBlankOption = [{ id: 0, name: "", color: "" }, ...queues];

    return (
        <div style={{ marginTop: 6 }}>
            <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel>{"Fila Pai"}</InputLabel>
                <Select
                    value={selectedFatherQueueId}
                    onChange={handleChange}
                    name={fatherqueue}
                    label={"Fila Pai"}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected && (
                                <Chip
                                    key={selected}
                                    style={{
                                        backgroundColor: queues.find((q) => q.id === selected)?.color,
                                    }}
                                    variant="outlined"
                                    label={queues.find((q) => q.id === selected)?.name}
                                    className={classes.chip}
                                />
                            )}

                        </div>
                    )}
                >
                    <MenuItem key={0} value={0} style={{ padding: 15 }}></MenuItem>
                    {queues.map((queue) => (
                        <MenuItem key={queue.id} value={queue.id}>
                            {queue.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default QueueSelectOne;
