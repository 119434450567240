import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function TagsContainer({ ticket }) {



  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {

    if (isMounted.current) {

      loadTags().then(() => {
        if (Array.isArray(ticket.tags)) {
          setSelecteds(ticket.tags);
        } else {
          setSelecteds([]);
        }
      });

    }
  }, [ticket]);

  const createTag = async (data) => {

    try {
      const { data: responseData } = await api.post(`/tags`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  }

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      console.log('data', data);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  }

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags/sync`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  }

  const onChange = async (value, reason) => {
    let optionsChanged = []

    if (reason === 'remove-option') {
      await syncTags({ ticketId: ticket.id, tags: value });
    }

    if (reason === 'create-option') {

      return;

      if (isArray(value)) {
        for (let item of value) {
          if (isString(item)) {

            const newTag = await createTag({ name: item, color: stringToColor(item) })
            optionsChanged.push(newTag);
          } else {
            optionsChanged.push(item);
          }
        }
      }
      await loadTags();
    }
    else {
      optionsChanged = value;
    }
    setSelecteds(optionsChanged);
    await syncTags({ ticketId: ticket.id, tags: optionsChanged });
  }


  return (
    <Paper style={{ padding: 12 }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selecteds}
        freeSolo
        onChange={(e, v, r) => onChange(v, r)}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{ backgroundColor: option.color || '#eee', textShadow: '1px 1px 1px #000', color: 'white' }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder="Tags" />
        )}
        PaperComponent={({ children }) => (
          <Paper style={{ width: 400, marginLeft: 12 }}>
            {children}
          </Paper>
        )}
      />

    </Paper>
  )
}